import React from 'react';

class Spacer extends React.Component {

  render() {

    const style = {
      height: this.props.height,
      backgroundColor: this.props.backgroundColor
    }

    return(
      <div style={style}>
      </div>
    )
  }
}

export default Spacer;
