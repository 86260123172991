import React, { Component } from 'react';
import Spacer from '../components/spacer.js';
import '../style/selling_points.scss';

class SellingPoints extends Component {

  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
  }

  state = {
    isVisible: false,
    h1: 'Experienced',
    h2: 'Connected',
    h3: 'Driven',
    p1: 'Established in 2002, Toby Richey & Associates is one of the largest and most experienced executive recruiting firms specializing in the placement of top-producing financial advisors with leading Wall Street wealth management firms.',
    p2: 'With client relationships spanning over 2 decades, we are deeply connected with the wealth management firms we represent, and we have cultivated relationships with the foremost financial advisors and teams in the industry.',
    p3: 'As proven leaders in this sector, we are dedicated to perform and positioned to execute. With aggregate placements of hundreds of millions of dollars in annual gross production and billions of dollars in assets, our track record speaks for itself.'
  }

  handleFadeIn() {

    const elements = []

    elements.push(document.getElementById('box-01'));
    elements.push(document.getElementById('box-02'));
    elements.push(document.getElementById('box-03'));
    elements.push(document.getElementById('box-04'));
    elements.push(document.getElementById('box-05'));
    elements.push(document.getElementById('box-06'));

    function unfade(element) {
        var op = 0.1;  // initial opacity
        var timer = setInterval(function () {
            if (op >= 1){
                clearInterval(timer);
            }
            element.style.opacity = op;
            element.style.filter = 'alpha(opacity=' + op * 100 + ")";
            op += op * 0.1;
        }, 10);
    }

    const anim01 = () => {
      unfade(elements[0]);
    }

    const anim02 = () => {
      unfade(elements[1]);
    }

    const anim03 = () => {
      unfade(elements[2]);
    }

    const anim04 = () => {
      unfade(elements[3]);
    }

    const anim05 = () => {
      unfade(elements[4]);
    }

    const anim06 = () => {
      unfade(elements[5]);
    }

    window.setTimeout(anim01.bind(this), 10);
    window.setTimeout(anim02.bind(this), 700);
    window.setTimeout(anim03.bind(this), 1400);
    window.setTimeout(anim04.bind(this), 10);
    window.setTimeout(anim05.bind(this), 700);
    window.setTimeout(anim06.bind(this), 1400);
  }

  fadeInOnPageLoad() {
  //   // If the initial window is high enough to show elements
    let element = document.getElementById('selling-points-container');

    if (element !== undefined) {
      if ((window.pageYOffset >= (window.pageYOffset + element.getBoundingClientRect().top - (.95 * window.innerHeight))) && (this.state.isVisible === false)) {
        // fade them in
        this.setState({isVisible: true})
        this.handleFadeIn();
      }
    }
  }

  handleScroll() {
    let element = document.getElementById('selling-points-container');

    if (element !== undefined) {
      if ((window.pageYOffset >= (window.pageYOffset + element.getBoundingClientRect().top - (.9 * window.innerHeight))) && (this.state.isVisible === false)) {
        this.setState({isVisible: true});
        this.handleFadeIn();
      }
    }
  }

  componentDidMount() {
    this.fadeInOnPageLoad();
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    this.setState({isVisible: false});
  }

  render() {
    return (
      <div id='selling-points-container'>
        <div id='selling-points'>
        <div className='deco-gold'></div>
          <div id='vertical-boxes-container'>
            <Spacer height='50px'/>
            <div className='box' id='box-01'>
              <div className='box-border-top' />
              <div className='header'>
                <h2>{this.state.h1}</h2>
              </div>
              <div className='body'>
                <p>{this.state.p1}</p>
              </div>
              <div className='box-border-bottom' />
            </div>
            <div className='vert-spacer' />
            <div className='box' id='box-02'>
            <div className='box-border-top' />
              <div className='header'>
                <h2>{this.state.h2}</h2>
              </div>
              <div className='body'>
                <p>{this.state.p2}</p>
              </div>
              <div className='box-border-bottom' />
            </div>
            <div className='vert-spacer' />
            <div className='box' id='box-03'>
            <div className='box-border-top' />
              <div className='header'>
                <h2>{this.state.h3}</h2>
              </div>
              <div className='body'>
                <p>{this.state.p3}</p>
              </div>
              <div className='box-border-bottom' />
            </div>
            <Spacer height='80px' />
          </div>
          <div id='horizontal-boxes-container'>
            <Spacer height='50px' />
            <div className='box' id='box-04'>
            <div className='box-border-top' />
              <div className='header'>
                <h2>{this.state.h1}</h2>
              </div>
              <div className='body'>
                <p>{this.state.p1}</p>
              </div>
              <div className='box-border-bottom' />
            </div>
            <div className='horizontal-connector' />
            <div className='box' id='box-05'>
            <div className='box-border-top' />
              <div className='header'>
                <h2>{this.state.h2}</h2>
              </div>
              <div className='body'>
                <p>{this.state.p2}</p>
              </div>
              <div className='box-border-bottom' />
            </div>
            <div className='horizontal-connector' />
            <div className='box' id='box-06'>
              <div className='box-border-top' />
              <div className='header'>
                <h2>{this.state.h3}</h2>
              </div>
              <div className='body'>
                <p>{this.state.p3}</p>
              </div>
              <div className='box-border-bottom' />
            </div>
            <Spacer height='80px' />
          </div>
        </div>
      </div>
    )
  }
}

export default SellingPoints;
