import React, { Component } from 'react';
import Anime from 'animejs/lib/anime.es.js';
import '../style/about.scss';
import member01 from '../images/headshots/toby_edit_03.jpg';
import member02 from '../images/headshots/blayne_edit_03.jpg';
import member03 from '../images/headshots/autumn.jpg';
import member04 from '../images/headshots/luke.jpg';

class About extends Component {

  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
  }

  state = {
    isVisible: false
  }

  handleFadeIn() {

    const elements = []

    elements.push(document.getElementById('profile-01'));
    elements.push(document.getElementById('profile-02'));
    elements.push(document.getElementById('profile-03'));
    elements.push(document.getElementById('profile-04'));

    function unfade(element) {
        var op = 0.1;  // initial opacity
        var timer = setInterval(function () {
            if (op >= 1){
                clearInterval(timer);
            }
            element.style.opacity = op;
            element.style.filter = 'alpha(opacity=' + op * 100 + ")";
            op += op * 0.1;
        }, 10);
    }

    const anim01 = () => {
      unfade(elements[0]);

      Anime({
        targets: '#gold-square-01',
        easing: 'easeInOutCubic',
        duration: 2000,
        translateX: 10,
        translateY: 10
      })

      Anime({
        targets: '#profile-img-01',
        easing: 'easeInOutCubic',
        duration: 2000,
        translateX: -10,
        translateY: -10
      })

    }

    const anim02 = () => {
      unfade(elements[1]);

      Anime({
        targets: '#gold-square-02',
        easing: 'easeInOutCubic',
        duration: 2000,
        translateX: 10,
        translateY: 10
      })

      Anime({
        targets: '#profile-img-02',
        easing: 'easeInOutCubic',
        duration: 2000,
        translateX: -10,
        translateY: -10
      })
    }

    const anim03 = () => {
      unfade(elements[2]);

      Anime({
        targets: '#gold-square-03',
        easing: 'easeInOutCubic',
        duration: 2000,
        translateX: 10,
        translateY: 10
      })

      Anime({
        targets: '#profile-img-03',
        easing: 'easeInOutCubic',
        duration: 2000,
        translateX: -10,
        translateY: -10
      })

    }

    const anim04 = () => {
      unfade(elements[3]);

      Anime({
        targets: '#gold-square-04',
        easing: 'easeInOutCubic',
        duration: 2000,
        translateX: 10,
        translateY: 10
      })

      Anime({
        targets: '#profile-img-04',
        easing: 'easeInOutCubic',
        duration: 2000,
        translateX: -10,
        translateY: -10
      })

    }

    window.setTimeout(anim01.bind(this), 10);
    window.setTimeout(anim02.bind(this), 500);
    window.setTimeout(anim03.bind(this), 1000);
    window.setTimeout(anim04.bind(this), 1500);
  }

  handleScroll() {
    let element = document.getElementById('profile-cards-container');

    if (element !== undefined) {
      if ((window.pageYOffset >= (window.pageYOffset + element.getBoundingClientRect().top - (.5 * window.innerHeight))) && (this.state.isVisible === false)) {
        this.setState({isVisible: true});
        this.handleFadeIn();
      }
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    this.setState({isVisible: false});
  }

  render() {
    return (
      <div>
        <div id='about_us'>
          <div id='profile-cards-container' className='container-fluid'>
            <div className='row profile-cards'>
              <div className='profile-card-container col-xs-12'>
                <div id='profile-01' className='profile-card'>
                  <div className='profile-pic-container'>
                    <div className='gold-backing' id='gold-square-01' />
                    <img src={member01} alt='Toby Richey' id='profile-img-01' />
                  </div>
                  <div className='text-container'>
                    <div className='header-container'>
                      <h3>Toby Richey<span className='pipe' /><span className='title'>CEO/President</span></h3>
                    </div>
                    <p>Toby Richey, CEO and President of Toby Richey & Associates, grew up in the brokerage industry and entered the field in 1989 as an institutional bond broker. In 1996, he began recruiting retail financial advisors at Ryman, Bell, Green and Michaels where he quickly became the firm's top producer. Toby started his firm in 2002, and has placed hundreds of financial advisors with billions of dollars in assets with the firm's premier Wall Street clients. Away from the office, Toby enjoys road and mountain biking, traveling, and spending time with his two children.
                    </p>
                  </div>
                </div>
              </div>
              <div className='profile-card-container col-xs-12'>
                <div id='profile-02' className='profile-card'>
                  <div className='profile-pic-container'>
                    <div className='gold-backing' id='gold-square-02' />
                    <img src={member02} alt='Blayne Huffman' id='profile-img-02' />
                  </div>
                  <div className='text-container'>
                  <div className='header-container'>
                    <h3>Blayne Huffman<span className='pipe' /><span className='title'>Senior Vice President</span></h3>
                  </div>
                  <p>Blayne is a Senior Vice President of Accounts at Toby Richey & Associates.  He joined the firm in 2006, and has extensive experience sourcing and placing financial advisors across numerous channels.  Blayne represents the candidate’s interests throughout the recruiting process, while simultaneously serving the client managers that he represents, ensuring that both parties' needs are met.  Outside of the office, Blayne enjoys spending time with his son, golf, and various outdoor sports.
                  </p>
                  </div>
                </div>
              </div>
              <div className='profile-card-container col-xs-12'>
                <div id='profile-03' className='profile-card'>
                  <div className='profile-pic-container'>
                    <div className='gold-backing' id='gold-square-03' />
                    <img src={member03} alt='Autumn Clark' id='profile-img-03' />
                  </div>
                  <div className='text-container'>
                  <div className='header-container'>
                    <h3>Autumn Clack<span className='pipe' /><span className='title'>Senior Vice President</span></h3>
                  </div>
                  <p>Autumn is a Senior Vice President of Accounts at Toby Richey & Associates. She has been placing financial advisors with the firm since 2010. She has a bachelor's degree from Adelphi University. Autumn loves being able to serve her clients and help guide advisors to find the right firm for them. Outside of the office, she enjoys long-distance running and the arts, particularly theatre, dance, and music.
                  </p>
                  </div>
                </div>
              </div>
              <div className='profile-card-container col-xs-12'>
                <div id='profile-04' className='profile-card'>
                <div className='profile-pic-container'>
                  <div className='gold-backing' id='gold-square-04' />
                  <img src={member04} alt='Luke Linford' id='profile-img-04' />
                </div>
                  <div className='text-container'>
                  <div className='header-container'>
                    <h3>Luke Linford<span className='pipe' /><span className='title'>Senior Vice President</span></h3>
                  </div>
                    <div className='hr' />
                    <p>Luke is a Senior Vice President of Sales Operations at Toby Richey & Associates. He joined the firm in 2017, and became one of the top producing recruiters before transitioning to sales management in 2019. Luke utilizes his years of formal leadership development training and sales experience to manage and coach the recruiting team in order to achieve the company’s tactical and strategic goals. In his spare time he is an avid powerlifter, whiskey collector and car enthusiast.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="svg-outer">
              <svg className='svg-inner gray' width='100%' height='100%' viewBox="0 0 100 100" preserveAspectRatio="none">
               <polygon points="0,0 0,100 100,100 100,0 50,98" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default About;
