import React, { Component } from "react";
import '../style/coverage.scss';

class Coverage extends Component {

  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleAnimation = this.handleAnimation.bind(this);
  }

  state = {
    isVisible: false
  }

  handleAnimation() {
    document.getElementById('map-pins').classList.add('expanded-clip');
  }

  handleScroll() {
    let element = document.getElementById('map-pins');

    if (element !== undefined) {
      if ((window.pageYOffset >= (window.pageYOffset + element.getBoundingClientRect().top - (.9 * window.innerHeight))) && (this.state.isVisible === false)) {
        this.setState({isVisible: true});
        this.handleAnimation();
      }
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    this.setState({isVisible: false});
  }

  render() {
    return (
    <div id='coverage-container'>
      <div id='coverage'>
        <div id='text-container'>
          <div id='text'>
            <h2>National Coverage</h2>
            <p>Toby Richey & Associates has deeply rooted
            relationships with financial advisors and wealth management firms across the country.
            We work extensively in all major money centers
            and cover small markets as well.</p>
          </div>
        </div>
        <div id='map-container'>
          <div id='map-pins'></div>
          <div id='map'></div>
        </div>
      </div>
    </div>)
  }
}

export default Coverage;
