import React from "react";
import Layout from "../components/layout";
import BackgroundSection from "../components/background_section";
import SubHero from '../components/sub_hero';
import About from '../components/about.js';
import SellingPoints from '../components/selling_points.js';
import Coverage from '../components/coverage.js';
import DecoBorder from '../components/deco_border.js';
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import '../style/hero_section.scss';
import '../style/reset.scss';
import '../style/svg.scss';
import 'font-awesome/css/font-awesome.min.css';

const IndexPage = ({data}) => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Toby Richey & Associates</title>
        <link rel="canonical" href="https://www.tobyrichey.com" />
      </Helmet>
      <BackgroundSection
        id='hero'
        styleClass="hero"
        title='Control Your Destiny'
        img={data.heroImg.childImageSharp.fluid}
        body="In today's rapidly evolving wealth management industry, a growing number of financial advisors are exploring their options. Whether seeking a better culture, greater resources or simply more control, Toby Richey & Associates will provide the expert advice and direction needed to navigate the many opportunities available to you."
        svgClass='svg-inner blue-gray' />
      <SubHero />
      <DecoBorder />
      <SellingPoints />
      <Coverage />
      <About />
    </Layout>
  )
};

export const query = graphql`
  {
    heroImg: file(relativePath: { eq:
    "default-background5.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }`
;

export default IndexPage;
