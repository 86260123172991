import React from 'react';
import '../style/animations.scss';

export default function SubHero() {
  return (
    <div className='subhero-container'>
      <div className='vert-spacer'></div>
      <div className='subhero fade-in'>
        <p>
          In today's rapidly evolving wealth management industry, a growing number of financial advisors are exploring their options. Whether seeking a better culture, greater resources or simply more control, Toby Richey & Associates will provide the expert advice and direction needed to navigate the many opportunities available to you.
        </p>
      </div>
      <div className='vert-spacer'></div>
    </div>
  )
}
