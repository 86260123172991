import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import '../style/hero_section.scss';
import '../style/background_section.scss';
import '../style/social_icons.scss';
import '../../node_modules/font-awesome/css/font-awesome.min.css';

export default function BackgroundSection({id, img, styleClass, title, body, svgClass}) {
  return (
    <BackgroundImage id={id} className={styleClass} fluid={img}>
      <div className='content-wrapper'>
        <div className='hero-text-wrapper'>
          <div id='logo-wrapper'>
            <div id='logo'></div>
          </div>
          <h1>{title}</h1>
          <div className='hero-floatbox'>
            <p className='fade-in'>{body}</p>
          </div>
        </div>
      </div>
    </BackgroundImage>
  )
}

BackgroundSection.defaultProps = {
  title: "",
  styleClass: "default-background-block"
}
